<template>
  <site-section section-class="news-search-result">
    <template v-slot:content>
      <media-block
        block-class="inner-news-tovar"
        block-head=""
        :media.sync="data"
        :size="8"
        :page="pagination"
        :update-media="updateSearch"
      />
      <div class="clear"></div>
    </template>
  </site-section>
</template>

<script>
import SiteSection from "@/components/page/SiteSection";
import MediaBlock from "@/components/page/media/MediaBlock";
export default {
  name: "SearchBlock",
  components: { MediaBlock, SiteSection },
  props: {
    data: Array,
    pagination: {
      type: Number,
      default: 1
    },
    updater: Function
  },
  methods: {
    updateSearch(sort, direction, page) {
      return this.updater(page);
    }
  }
};
</script>

<style scoped></style>

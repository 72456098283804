<template>
  <div class="wrapper-inner">
    <div class="inner-content">
      <h1 class="header-search">Результаты поиска</h1>
      <section class="search-block-inner">
        <div class="top-search">
          <a href="#" @click.prevent="searchVideo()">
            <img
              class="search-img"
              src="@/assets/img/search.svg"
              alt="Search"
            />
          </a>

          <input
            @keydown.enter="searchVideo()"
            class="search-input"
            v-model.trim="searchInput"
            type="text"
            placeholder="Введите название видео или спикера  "
          />
          <a class="close-search" href="#" @click.prevent="clearSearch">✕</a>
        </div>
      </section>
      <search-block
        :pagination="page"
        :data.sync="searchVideos"
        :updater="searchVideo"
        v-if="searchInput"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SearchBlock from "@/components/page/search/SearchBlock";
export default {
  name: "SearchResult",
  components: { SearchBlock },
  data() {
    return {
      page: 1
    };
  },
  props: {
    data: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapGetters(["searchVideos"]),
    searchInput: {
      get() {
        return this.data;
      },
      set(n) {
        this.page = 1;
        if (n) {
          this.$router.replace({ params: { data: n } });
        } else {
          this.$router.replace({ name: "SearchResult" });
        }
      }
    }
  },
  methods: {
    ...mapActions(["getSearchVideos"]),
    clearSearch() {
      this.searchInput = "";
      this.searchVideo(1);
    },
    searchVideo(page) {
      if (page) {
        this.page = page;
      }
      let s = this.searchInput;
      return this.getSearchVideos({
        page: this.page,
        search: s,
        size: 8
      });
    }
  },
  created() {
    this.searchVideo();
  }
};
</script>

<style scoped></style>
